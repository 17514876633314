.img-bg {
    /* The image used */
    background-image: url('../../img/loggwall.jpg');

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(0,0,0,.5);
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0,0,0,.5);
}

.separator:not(:empty)::before {
    margin-right: .25em;
}

.separator:not(:empty)::after {
    margin-left: .25em;
}

/* Non-Retina */
@media screen and (-webkit-max-device-pixel-ratio: 1) {

}

/* Retina */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    .img-bg {
        /* The image used */
        background: rgb(34,193,195);
        background: linear-gradient(319deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);

        /* Full height */
        height: 100vh;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

/* iPhone Y */
@media screen and (max-device-width: 480px) and (orientation:portrait) {
    .img-bg {
        /* The image used */
        background: rgb(34,193,195);
        background: linear-gradient(319deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);

        /* Full height */
        height: 100vh;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

/* iPhone X */
@media screen and (max-device-width: 480px) and (orientation:landscape) {

}

/* iPad Y */
@media screen and (min-device-width: 481px) and (orientation:portrait) {

}

/* iPad X */
@media screen and (min-device-width: 481px) and (orientation:landscape) {

}

@media screen and (max-width: 1250px) {
    .screen-ratio-125 {
        justify-content: center !important;

        padding: 0 !important;
    }
}